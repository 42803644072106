(function ($) {
  /**
   * Basic sub-block toggle for gnav utility templates
   * inside this formatter
   * sub-templates need to have a trigger with class .js-gnav-util-trigger
   * the following div should have .js-gnav-util__content .hidden
   *
   * NOTE: the cart utility link manages its' own toggle so we can render
   * cart content via js when opening
   * ~/smashbox_base/template_api/navigation/gnav_cart_v1/gnav_cart_v1.js
   *
   */
  Drupal.behaviors.utilityNavFormatter = {
    attach: function (context) {
      var $nav = $('.js-nav--utility', context);
      var $block = $('.gnav-util-formatter__item', context);
      var $trigger = $('.js-gnav-util-trigger', $block);
      var $menuTrigger = $('.js-gnav-mobile-trigger', $block);
      var $closeTrigger = $('.js-gnav-util-close', $block);
      var $blockContent = $('.js-gnav-util__content', $block);
      var $body = $('body', context);
      var $contentDim = $body.find('.js-content-dim');
      var $siteHeader = $('.site-header', context);
      var $siteHeaderNav = $('.site-header__section--nav', context);
      var $mobileBackButton = $('.js-mobile-checkout-back', context);
      var mobileCheckout = !!$('body.checkout, body.viewcart').length;

      if (mobileCheckout) {
        if (!$('.checkout--confirmation-page').length) {
          $mobileBackButton.appendTo('.site-header .gnav-util-formatter');
          $mobileBackButton.removeClass('hidden');
        } else if ($('.checkout--confirmation-page').length) {
          $mobileBackButton.remove();
        }
      } else {
        $mobileBackButton.remove();
      }
      function close() {
        $blockContent.addClass('hidden');
        $trigger.removeClass('active');
        $(this).next('.js-gnav-util__content').removeClass('hidden');

        // Once other events have triggered, check if the menu link was clicked
        // If it was, we want to keep the black theme
        setTimeout(function () {
          if (!$('.site-header__main .menu__item.active', context).length) {
            $body.removeClass('gnav-active');
          }
        }, 100);
      }

      function navDimensions() {
        var offset = $siteHeader.outerHeight(true);
        var height = $(window).innerHeight - offset;

        $siteHeaderNav.css({
          top: offset,
          height: height
        });
      }

      $(window).on(
        'resize',
        _.debounce(function () {
          if ($body.hasClass('mobile-gnav-active')) {
            navDimensions();
          }
        }, 250)
      );

      $nav.on('clickoutside', function () {
        $blockContent.addClass('hidden');
        close();
      });

      $trigger.once().on('click', function (event) {
        // If the menu was previously open, immediately remove its active classes
        // We're doing this instead of click because the gnav waits for transitions to end before removing classes
        
        if (Drupal?.settings?.disable_cart_overlay) {
          return;
        }
        
        // IAM 2.0 Disable account overlay
        var clickedAccountIcon = $(this).hasClass('gnav-util__link--account');
        if (clickedAccountIcon && Drupal?.settings?.disable_account_overlay) {
          return;
        }
        
        event.preventDefault();
        $body.removeClass('mobile-gnav-active');
        var $thisContent = $(this).next('.js-gnav-util__content');

        $blockContent.not($thisContent).addClass('hidden');
        $trigger.removeClass('active');
        $(this).toggleClass('active', $thisContent.hasClass('hidden'));
        $thisContent.toggleClass('hidden');

        // We want to check if we're just switching to another utility nav item
        // If we are we want to keep the gnav-active on the body
        $body.toggleClass('gnav-active', !$thisContent.hasClass('hidden'));

        // focus search input if search icon clicked
        if (!$thisContent.hasClass('hidden')) {
          var $searchForm = $thisContent.find('.js-end-esearch-nav');
          var $searchInput = $searchForm.find('.js-search-term--nav');

          $searchInput.trigger('blur').val('').focus();
        }
      });

      // hamburger adds a body class so we can toggle main nav
      $menuTrigger.once().on('click', function (event) {
        event.preventDefault();
        $blockContent.addClass('hidden');
        $body.toggleClass('mobile-gnav-active');
        $body.removeClass('gnav-active');
        navDimensions();
      });

      // content block close
      // js-gnav-util-close
      $closeTrigger.once().on('click', function (event) {
        event.preventDefault();
        $(this).parent().addClass('hidden');
        $trigger.removeClass('active');
        $body.removeClass('gnav-active');
      });

      // content block close when overlay is clicked
      // .gnav-active .js-content-dim
      $contentDim.once().on('click', function () {
        close();
      });
    }
  };
})(jQuery);
